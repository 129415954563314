
 
if (document.currentScript) { 

} else {

}/**
 *
 * Custom function
 *
 */

(function($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() { 
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function() {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function() {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function() {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function() {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function() {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function() {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function(e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function() {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function() {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function(event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function() {
                var table = $(this);
                $('.angle-scroll-left').click(function(event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function() {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }


    /**
     * Funzione per l'attivazione del menu responsive
     */

    function responsiveMenu(viewportSize) {
        $('.main-navigation').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
            });
        });

        if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
            var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
            var fixedMenu = $('#js-fixed-menu')[0];

            if (!fixedMenu) return;

            var stickyHeader = new Waypoint.Sticky({
                element: fixedMenu
            });

            $('.main-navigation').find('a[href="#"]').each(function() {
                $(this).click(function(event) {
                    $('ul.sub-menu').removeClass('open');
                    $(this).next().toggleClass('open');
                });
            });

            $("#js-menu-offcanvas-button-open").click(function() {
                menuPrimaryOffcanvas.addClass("offcanvas-open");
            });

            $("#js-menu-offcanvas-button-close, #js-menu-primary-offcanvas a").click(function() {
                menuPrimaryOffcanvas.removeClass("offcanvas-open");
            });
        } else {
            var stickyHeader = new Waypoint.Sticky({
                element: $('#js-header')[0],
                // offset: -250 // l'offset è utile se non vuoi far partire subito fisso il menu
            });
        }
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function(index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function(index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };
             
            pswpItems.push(myImage);
        });


        galleryItems.each(function(index) {
            $(this).click(function(evt) {
                evt.preventDefault();
                openPhotoSwipe(pswpItems, index);
            });
        });
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


    function smoothScroll() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if(target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                    }
                    else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }
                    
                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);
                    
                    return false;
                }
            }
        });
    }

    function startOwl(selector) {
        $(selector).each(function(arguments) {
            var owl = $(this);
            var params = {
                nav : owl.data('owl-navigation'),
                navSpeed : owl.data('owl-slide-speed'),
                autoplay : owl.data('owl-autoplay'),
                dots : owl.data('owl-dots'),
                dotsSpeed : owl.data('owl-dots-speed'),
                loop : owl.data('owl-loop'),
                autoHeight : owl.data('owl-auto-height'),
                lazyLoad : true,
                navText: [ 
                    "<i class='icon-edt-arrow-left-2'></i>",
                    "<i class='icon-edt-arrow-right-2'></i>"
                ]
            };

            if (owl.data('owl-single-item')) {
                params['items'] = 1;
            } else {
                params['responsive'] = {
                    0: {
                        items: owl.data('owl-items-xs'),
                    },
                    768: {
                        items: owl.data('owl-items-sm'),
                    },
                    992: {
                        items: owl.data('owl-items-md'),
                    },
                    1200: {
                        items: owl.data('owl-items-lg'),
                    },
                    1440: {
                        items: owl.data('owl-items-xl'),
                    }
                };
            }

            owl.owlCarousel(params);

            if(owl.data('owl-prev')){
                $(owl.data('owl-prev')).click(function(){
                    owl.trigger('prev.owl.carousel');
                });
            }
            if(owl.data('owl-next')){
                $(owl.data('owl-next')).click(function(){
                    owl.trigger('next.owl.carousel');
                });
            }
        });
    }

	function getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	/*
	 *	Funzione per la gestione dei bottoni "condividi" legati ai social
	 */

	 function edt_storeReferrer() {
		var edt_referrer = getCookie("edt_referrer");

		if( !edt_referrer || ( typeof(edt_referrer) != "undefined" && edt_referrer == "" )) {
			setCookie("edt_referrer", document.referrer);
		}
	}

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function translateCountdown( langCountdown ) {

        countdown.resetLabels();
        
        if ( langCountdown == 'it' ) {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if ( langCountdown == 'de' ) {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if ( langCountdown == 'fr' ) {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

	function edtCountdown(){        

		jQuery( ".edt_countdown_class" ).find('.item').each( function(){ 
            
		    var dataInizio = "";
		    var questo = jQuery(this);
		    jQuery.post(
			    url.ajax_url, 
			    {
			        'action': 'edita_ajax_get_start_countdown_date',
			        'offerId':   questo.data('offerid'),			        
			    }, 
			    
			    function(response){
					setInterval(function() {
                        stampaCountdown( questo, response );
                    }, 3600);
			    }
			);
	    });
	}

	function stampaCountdown( item, response ){

        jQuery( item ).find( '.pageTimer' ).empty().append(
            moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
        );        
	}

	function closePhotoswipe() {
	
	    $('.pswp__button--close').click(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	
	    $(window).scroll(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	}

    /*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {
		
		var filterSelect = '.select-offers',
			container = '.mix-filter-support';

		if ( $(container).length ) {
			var mixer = mixitup(container);

			$(filterSelect).on('change', function(){
				mixer.filter(this.value);
			});
		}
	}

    /**
     * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
     * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
     * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
     * ritorna un boleano.
     */
    var mapsMobileUrl = {
        isAndroid: function() {
            return /(android)/i.test(navigator.userAgent);
        },
        isIos: function() {
            return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
        }
    };


    function injectorLinkMaps()
    {
        var buttonMaps = $('#js-fixed-menu').find('.navigator');

        if ( mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'href', phpMapsMobileUrl.android );

        if ( mapsMobileUrl.isIos() ) buttonMaps.attr( 'href', phpMapsMobileUrl.ios );

        if ( ! mapsMobileUrl.isAndroid() && ! mapsMobileUrl.isIos() ) buttonMaps.hide();
    }


    $j(document).ready(function() {

        allClick();
        responsiveTable();
        addImageSizeForPhotoSwiper();
        iframeModalOpen();
        responsiveMenu(1199);
        filterElements();
        injectorLinkMaps();

		// salvo il referrer in un cookie di sessione
		edt_storeReferrer();
        
        $('.feedback-description').collapser({
			mode: 'chars',
			truncate: 360,
			showText: '[ + ]',
			hideText: '[ - ]',
			controlBtn: 'opener',
		});
        
        startOwl('.owl-carousel');
        smoothScroll();

		closePhotoswipe();

        if ( typeof lang && lang != 'en' ) {
            translateCountdown( lang );
		}

        edtCountdown();

        $('.gform_wrapper form').each(function() {

            $(this).checkEditaForm();
        });

		
        $('.checkNewsletter').each(function() {
            $(this).checkEditaForm();
        });

        $('.button-modal-open').click(function(event){
            event.preventDefault();

            var form = $('#richiestapreventivo-modal');
            var target = $(this).data('target');

            if (target) {
				form.find('input.set-provenienza').attr('value', target);
				console.log( 'provenienza=', form.find('input.set-provenienza').val(), target);//debug
			}

            $('#richiestapreventivo-modal').modal('show');
        });

        var $win = $(window);
        
        if(!$('body').hasClass('page-template-template-no-menu') && !$('body').hasClass('page-template-template-goback')) {
            $win.scroll(function () {
                if ($win.scrollTop() != 0) {
                    $('#js-header').addClass('scroll');
                    $('#js-fixed-menu').removeClass('top');
                }
                else {
                    $('#js-header').removeClass('scroll');
                    $('#js-fixed-menu').addClass('top');
                }         
            });
        }
        else {
            if ($win.scrollTop() == 0) {
                $('#js-header').addClass('scroll');
            }
        }
        
		
    });


    $j(window).load(function() {
        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */
        // if ( $('body').hasClass('page-template-template-mappa') ) {
            
            $.getScript('https://www.google.com/jsapi', function() {            
                if(url.key) {
                    var map_params = 'language=' + url.lingua + '&key=' + url.key;
                }
                else {
                    var map_params = 'language=' + url.lingua;
                }
                
                google.load('maps', '3', {
                    other_params: map_params + '&libraries=places',
                    callback: function() {
                        initializeGmap();
                    }
                });
            });
        // }

        var gallery = $('.gallery');

        if ( gallery.length ) {
            gallery.each(function( index ) {
                
                initPhotoSwiper( "#" + $( this ).attr('id') + " .gallery-item");
            });
        }

        initPhotoSwiper(".entry-content .single-image");
        initPhotoSwiper('.mappa-lago')

        $('.section-form .form-group').equalHeights();
        if ( !$('body').hasClass('is-mobile') ) $('footer .eqh-col').equalHeights();
        if ( !$('body').hasClass('is-mobile') ) $('.feedback-slideshow article').equalHeights();

        if ($(window).width() >= 1187) {
            $('.room-block').equalHeights();
        }
    });

    $j(window).resize(function() {



    });

})(jQuery);
